//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
import EventBus from '@/shared/scripts/eventbus';
import paymentHelper from '@/shared/components/checkout/paymentHelper';
import visaLogo from '~/assets/card_visa.png';
import amexLogo from '~/assets/card_americanex.png';
import discoverLogo from '~/assets/card_discover.png';
import mastercardLogo from '~/assets/card_mastercard.png';
import NextTab from '~/components/checkout/components/NextTab.vue';
import { TabIds } from '~/components/checkout/tabs/tabIds';
import { toMonthString } from '~/utils/date';
import { windowMatchMediaMixin } from '~/mixins/matchMedia';
import { AffirmLogo } from '@fc/angie-ui';

const logos = {
  'American Express': amexLogo,
  Discover: discoverLogo,
  MasterCard: mastercardLogo,
  Visa: visaLogo,
  visa: visaLogo,
  amex: amexLogo,
  discover: discoverLogo,
  mastercard: mastercardLogo,
};

export default {
  name: 'ReviewOrderTab',
  mixins: [windowMatchMediaMixin],
  components: {
    NextTab,
    AffirmLogo,
  },
  computed: {
    ...mapState({
      shipping: state => state.checkout.shipping,
      token: state => state.checkout.paymentInformation.token,
      cardPostalCode: state => state.checkout.paymentInformation.postalCode,
      paymentMethod: state => state.checkout.paymentInformation.paymentMethod,
      email: state => state.checkout.checkoutEmail,
      paymentInformation: state => state.checkout.paymentInformation.paymentMethodObj, // handle paymentIntent
      shippingError: state => state.checkout.shippingError,
    }),
    ...mapGetters([
      'bundle',
      'cartqty',
      'confirmation',
      'isSubsInCart',
      'order',
      'packageInCart',
      'summary_subtotal',
      'summary_tax',
      'summary_total',
      'summary_shipping',
      'processingPaymentMethod',
      'cartProducts',
      'membershipInCart',
      'isEligibleGen2Upgrade',
      'isGen2UpgradeInCart',
    ]),
    paymentMethodInformation() {
      const paymentMethodData = this.paymentInformation?.card;
      const isDataPresent = this.paymentInformation || this.token;
      const month = isDataPresent ? toMonthString(paymentMethodData.exp_month) : null;
      const year = isDataPresent ? (paymentMethodData.exp_year).toString().slice(-2) : null;

      return {
        logo: isDataPresent ? logos[paymentMethodData.brand] : null,
        last4: isDataPresent ? paymentMethodData.last4 : null,
        expDate: isDataPresent ? `${month}/${year}` : null,
      };
    },
    isAffirm() {
      return this.paymentMethod === 'affirm';
    },
    totalItems() {
      return `${this.cartqty} item${this.cartqty === 1 ? '' : 's'}`;
    },
    buttonText() {
      return this.isAffirm ? 'Continue with Affirm' : 'Place Order';
    },
    shippingText() {
      return this.$options.filters.currency(this.summary_shipping);
    },
    reminderText() {
      const isMonthly = this.membershipInCart?.metadata?.monthlyPrice > 0 ?? false;
      return isMonthly ? 'monthly' : 'annually';
    },
    displaySubscriptionReminder() {
      return !!this.membershipInCart;
    },
  },
  mounted() {
    document.activeElement.blur();

    if (window.affirm && window.affirm.ui) {
      window.affirm.ui.ready(() => {
        window.affirm.ui.error.on('close', () => {
          this.$store.commit('SET_PROCESSING_PAYMENT_METHOD', false);
        });
      });
    }
  },
  methods: {
    async handlePlaceOrder() {
      this.$store.commit('SET_PAYMENT_ATTEMPT_ERROR', '');
      this.$store.commit('SET_PROCESSING_PAYMENT_METHOD', true);

      try {
        if (this.isAffirm) {
          await this.handleAffirmOrder();
        } else {
          await this.handleCreditCardOrder();
        }
      } catch (err) {
        this.handleError(err.message);
      }
    },
    async handleAffirmOrder() {
      const affirmData = {
        store: this.$store,
        apiClient: this.$api_client,
        bundle: this.bundle,
        cartProducts: this.cartProducts,
        method: this.$store.state.checkout.paymentInformation.paymentMethod,
        affirm: window.affirm,
      };

      const affirmObj = await paymentHelper.createAffirmCheckoutObject(affirmData);
      await paymentHelper.launchAffirm(affirmObj);

      await this.completeOrder();
      this.$store.commit('reset');
    },
    async handleCreditCardOrder() {
      if (!this.$store.state.checkout.paymentInformation?.paymentMethodObj?.id) {
        throw new Error('There was a problem with your payment method. Please try again.');
      }

      await this.completeOrder();
    },
    async completeOrder() {
      if (this.isSubsInCart) {
        // attaches Payment Method from $store to customer in Stripe
        await this.$store.dispatch('orders/updateCustomer');
      }

      await this.$store.dispatch('orders/confirmOrder', { ordersApi: this.$ordersApi, bugsnag: this.$bugsnag });

      if (this.shippingError) {
        this.$store.commit('SET_PROCESSING_PAYMENT_METHOD', false);
        return;
      }

      this.$emit('transactionCompleted', this.confirmation); // this pushes to the confirmation page
      await this.trackOrderSuccess();
    },
    async trackOrderSuccess() {
      const items = this.getOrderItems();
      EventBus.$emit('trackPurchase', {
        order: this.confirmation.order,
        orderNumber: this.confirmation.orderNumber,
        isNewCustomer: this.confirmation.isNewCustomer,
        bundle: this.bundle,
        method: this.$store.state.checkout.paymentInformation.paymentMethod,
        items,
      });
      this.$analytics.orderCompleted({ ...this.confirmation, products: this.bundle, packageInCart: !!this.packageInCart });
    },
    getOrderItems() {
      return this.confirmation?.cart.map((cartItem) => {
        const product = this.$store.getters.getProductVariantById(cartItem.id);
        return {
          item_id: cartItem.id,
          item_name: product?.title || 'Unknown',
          price: product?.variants[0]?.price || 0,
          quantity: cartItem.qty,
          item_category: product?.productType || 'Unknown',
          item_variant: product?.variants.find((variant) => variant.id === cartItem.id)?.title || 'Unknown',
        };
      });
    },
    handleError(message) {
      this.$store.dispatch('handlePaymentAttemptError', message);
      EventBus.$emit('setTab', TabIds.PAYMENT);
      this.$bugsnag.notify(new Error(message));
    },
  },
};
